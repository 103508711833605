import { ref } from 'vue'
import { defineStore } from 'pinia'
//import { initConf } from "@/api/common";
import { useProductLineStore } from '@/store/modules/product_line'
import { getProductLine } from '@/utils/cache/local-storage'

export const useConfStore = defineStore('conf', () => {
  // 产品线配置
  const productLineOptions = ref<any[]>([])
  // 是否海外
  const isOversea = ref<boolean>(IsOversea())

  const init = (data) => {
    if (productLineOptions.value.length > 0) {
      return
    }

    productLineOptions.value = data.map((item) => ({
      label: item.name,
      value: item.name_en.toUpperCase()
    }))

    if (productLineOptions.value.length > 0) {
      const prodStore = useProductLineStore()
      let hasSet = false
      const local = getProductLine()
      if (local !== null && local !== '') {
        productLineOptions.value.forEach((item) => {
          if (item.value === local) {
            prodStore.updateProductLine(local)
            hasSet = true
          }
        })
      }
      if (hasSet) {
        return
      }
      prodStore.updateProductLine(productLineOptions.value[0].value)
    }
  }

  const reset = () => {
    productLineOptions.value = []
    const prodStore = useProductLineStore()
    prodStore.updateProductLine('QY')
  }

  const setProductLineOptions = (opts: any[]) => {
    productLineOptions.value = opts
  }

  const getProductLineOptions = () => {
    return productLineOptions.value
  }

  return { productLineOptions, isOversea, init, setProductLineOptions, getProductLineOptions, reset }
})

export const IsOversea = () => {
  return import.meta.env.VITE_IS_OVERSEA === 'true'
}
