import router from '@/router'
import { useUserStoreHook } from '@/store/modules/user'
import { usePermissionStoreHook } from '@/store/modules/permission'
import { setRouteChange } from '@/hooks/useRouteListener'
import { useTitle } from '@/hooks/useTitle'
import { getToken } from '@/utils/cache/cookies'
import { fixBlankPage } from '@/utils/fix-blank-page'
import isWhiteList from '@/config/white-list'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const { setTitle } = useTitle()
NProgress.configure({ showSpinner: false })

router.beforeEach(async (to, _from, next) => {
  fixBlankPage()
  NProgress.start()
  const userStore = useUserStoreHook()
  const permissionStore = usePermissionStoreHook()
  const token = getToken()

  // 判断该用户是否已经登录
  if (!token) {
    // 如果在免登录的白名单中，则直接进入
    if (isWhiteList(to)) {
      next()
    } else {
      // 其他没有访问权限的页面将被重定向到登录页面
      NProgress.done()
      next('/login')
    }
    return
  }

  // 如果已经登录，并准备进入 Login 页面，则重定向到主页
  if (to.path === '/login') {
    NProgress.done()
    return next({ path: '/' })
  }

  // 如果用户已经获得其权限角色
  if (userStore.roles.length !== 0) return next()

  // 否则要重新获取权限角色
  // try {
  await userStore.getInfo()
  await userStore.getMenus()
  await userStore.getProductLines()
  await userStore.getButtonPermission()
  const roles = userStore.menus
  // 根据角色生成可访问的 Routes（可访问路由 = 常驻路由 + 有访问权限的动态路由）
  permissionStore.setRoutes(roles)
  // 将'有访问权限的动态路由' 添加到 Router 中
  permissionStore.dynamicRoutes.forEach((route) => route && router.addRoute(route))

  console.log(router.getRoutes())
  // 确保添加路由已完成
  // 设置 replace: true, 因此导航将不会留下历史记录
  // next()
  next({ ...to, replace: true })
  // } catch (err: any) {
  //   // 过程中发生任何错误，都直接重置 Token，并重定向到登录页面
  //   console.log('error:', err)
  //   userStore.resetToken()
  //   NProgress.done()
  //   next('/login')
  // }
})

router.afterEach((to) => {
  setRouteChange(to)
  setTitle(to.meta.title)
  NProgress.done()
})
