import type * as Login from './types/login'
import { axiosRequest, ContentTypeEnum } from '@/utils/service'
import { MANAGEMENT_LOGIN, MANAGEMENT_MENUS, MANAGEMENT_PRODUCT_LINES } from '@/constants/api-list'

/** 登录并返回 Token */
export function loginApi(data: Login.LoginRequestData) {
  return axiosRequest.post({
    url: MANAGEMENT_LOGIN,
    data: data,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

/** 登录并返回 Token */
export function getMenusApi() {
  return axiosRequest.get({
    url: MANAGEMENT_MENUS
  })
}

/** 登录并返回 Token */
export function getProductLinesApi() {
  return axiosRequest.get({
    url: MANAGEMENT_PRODUCT_LINES
  })
}
