import { ref } from 'vue'
import store from '@/store'
import { defineStore } from 'pinia'
import { usePermissionStore } from './permission'
import { useTagsViewStore } from './tags-view'
import { useSettingsStore } from './settings'
import { getToken, getUser, removeToken, removeUser, setToken, setUser } from '@/utils/cache/cookies'
import router, { resetRouter } from '@/router'
import { loginApi, getMenusApi, getProductLinesApi } from '@/api/login'
import { type LoginRequestData } from '@/api/login/types/login'
import { type RouteRecordRaw } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useConfStore } from '@/store/modules/conf'

export const useUserStore = defineStore('user', () => {
  const token = ref<string>(getToken() || '')
  const roles = ref<string[]>([])
  const menus = ref<any>([])
  const username = ref<string>('')

  const buttonPermission = ref<string[]>([])

  const permissionStore = usePermissionStore()
  const tagsViewStore = useTagsViewStore()
  const settingsStore = useSettingsStore()

  /** 设置角色数组 */
  const setRoles = (value: string[]) => {
    roles.value = value
  }
  /** 登录 */
  const login = async ({ username, password }: LoginRequestData) => {
    const res = await loginApi({ username, password })
    console.log(res)

    if (res.success) {
      const { login_credential } = res.result
      setToken(login_credential)
      setUser(username)
      token.value = login_credential
    } else {
      console.log(res.result)
      ElMessage({
        message: res.result.err_msg || res.result.error_msg,
        type: 'error'
      })
    }
  }

  const getMenus = async () => {
    const res = await getMenusApi()
    if (res.success) {
      menus.value = res.result.menus || []
    } else {
      logout()
    }
  }

  const getProductLines = async () => {
    const res = await getProductLinesApi()
    if (res.success) {
      useConfStore().init(res.result.data)
    } else {
      logout()
    }
  }

  /** 获取用户详情 */
  const getInfo = async () => {
    // const { data } = await getUserInfoApi()
    // username.value = data.username
    // 验证返回的 roles 是否为一个非空数组，否则塞入一个没有任何作用的默认角色，防止路由守卫逻辑进入无限循环
    // roles.value = data.roles?.length > 0 ? data.roles : routeSettings.defaultRoles
    username.value = getUser() ?? ''
    roles.value = ['admin']
  }
  /** 切换角色 */
  const changeRoles = async (role: string) => {
    const newToken = 'token-' + role
    token.value = newToken
    setToken(newToken)
    await getInfo()
    permissionStore.setRoutes(roles.value)
    resetRouter()
    permissionStore.dynamicRoutes.forEach((item: RouteRecordRaw | any) => {
      router.addRoute(item)
    })
    _resetTagsView()
  }

  const getButton = async (menus) => {
    for (const item of menus) {
      if (item.children.length > 0) {
        await getButton(item.children)
      }

      if (item.type === 'btn') {
        buttonPermission.value.push(item.path)
      }
    }
  }

  const getButtonPermission = async () => {
    buttonPermission.value = []
    await getButton(menus.value)
    console.log('buttonPermission.value', buttonPermission.value)
  }

  /** 登出 */
  const logout = () => {
    removeToken()
    removeUser()
    useConfStore().reset()
    token.value = ''
    roles.value = []
    resetRouter()
    _resetTagsView()
  }
  /** 重置 Token */
  const resetToken = () => {
    removeToken()
    token.value = ''
    roles.value = []
  }
  /** 重置 Visited Views 和 Cached Views */
  const _resetTagsView = () => {
    if (!settingsStore.cacheTagsView) {
      tagsViewStore.delAllVisitedViews()
      tagsViewStore.delAllCachedViews()
    }
  }

  return {
    token,
    roles,
    menus,
    username,
    buttonPermission,
    setRoles,
    login,
    getInfo,
    getMenus,
    getButtonPermission,
    changeRoles,
    logout,
    resetToken,
    getProductLines
  }
})

/** 在 setup 外使用 */
export function useUserStoreHook() {
  return useUserStore(store)
}
