const Layouts = () => import('@/layouts/index.vue')

export const VersionRouter = {
  path: '/version',
  component: Layouts,
  name: 'version',
  meta: {
    title: '版本管理',
    svgIcon: 'menu',
    alwaysShow: true // 将始终显示根菜单
  },
  children: [
    {
      path: 'client_version',
      component: () => import('@/views/version/client_version/index.vue'),
      name: 'ClientVersion',
      meta: {
        title: '客户端版本控制'
      }
    },
    {
      path: 'client_version/edit',
      component: () => import('@/views/version/client_version/edit/index.vue'),
      name: 'ClientVersionEdit',
      meta: {
        title: '客户端版本控制-编辑',
        hidden: true
      }
    },
    {
      path: 'client_version/android/edit',
      component: () => import('@/views/version/client_version/edit/android.vue'),
      name: 'ClientVersionAndroidEdit',
      meta: {
        title: '客户端android版本控制-编辑',
        hidden: true
      }
    },
    {
      path: 'client_params',
      component: () => import('@/views/version/client_params/index.vue'),
      name: 'ClientParams',
      meta: {
        title: '客户端配置参数'
      }
    },
    {
      path: 'client_params/edit',
      component: () => import('@/views/version/client_params/edit/index.vue'),
      name: 'ClientParamsEdit',
      meta: {
        title: '客户端配置参数-编辑',
        hidden: true
      }
    }
  ]
}
