// core
import { createApp, Directive } from 'vue'

import App from './App.vue'
import store from '@/store'
import router from '@/router'
import '@/router/permission'
// load
import { loadSvg } from '@/icons'
import { loadPlugins } from '@/plugins'
import { loadDirectives } from '@/directives'
// css
import 'normalize.css'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import '@/styles/index.scss'
import { useUserStoreHook } from '@/store/modules/user'

// 引入富文本组件
import { QuillEditor } from '@vueup/vue-quill'
// 引入富文本组件样式
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import VueClipboards from 'vue-clipboard2'
import VueUeditorWrap from 'vue-ueditor-wrap';
import { useConfStore } from "@/store/modules/conf";

const app = createApp(App)
app.component('QuillEditor', QuillEditor)
app.use(VueClipboards)
app.use(VueUeditorWrap)

loadPlugins(app)
loadSvg(app)
loadDirectives(app)

app.use(store).use(router)
router.isReady().then(() => {
  app.mount('#app')
})

// 原型链挂载字符串占位符方法
String.prototype.format = function () {
  if (arguments.length == 0) return this
  let param = arguments[0]
  let s = this
  if (typeof param == 'object') {
    for (let key in param) s = s.replace(new RegExp('\\{' + key + '\\}', 'g'), param[key])
    return s
  } else {
    for (let i = 0; i < arguments.length; i++) s = s.replace(new RegExp('\\{' + i + '\\}', 'g'), arguments[i])
    return s
  }
}

const hasPermission: Directive = {
  mounted(el, binding) {
    const { buttonPermission } = useUserStoreHook()
    const hasPermission = buttonPermission.some((item) => item === binding.value)
    if (!hasPermission) {
      console.log('has --->', hasPermission, binding.value)
      el.style.display = 'none'
    }
  }
}

app.directive('has', hasPermission)

// 初始化配置
// useConfStore().init()
