import type {AxiosTransform} from '@minko-fe/axios-pro'
import {AxiosPro} from '@minko-fe/axios-pro'
import {getToken} from './cache/cookies'
import {useSaasStore} from '@/store/modules/saas'
import {useProductLineStore} from '@/store/modules/product_line'
import router from '@/router/index'
import axios from 'axios'

export * from '@minko-fe/axios-pro'

export enum ErrorCode {
  verification = 400
}

const getSaasParams = () => {
  const saasStore = useSaasStore()
  const prodStore = useProductLineStore()
  const saas_product_line = prodStore.product_line.toUpperCase()
  const saas_app_id = saasStore.saas_app_id

  let saas_token = saasStore.getSaasToken()
  //解析token，如果与当前产品线不一致，则不使用
  if (!!saas_token) {
    const splits = saas_token?.split('.')
    if (splits.length > 1) {
      const payload = JSON.parse(window.atob(splits[1]))
      if (saas_product_line !== payload.issue_to_pl) {
        console.log('产品线不同', saas_product_line, payload.issue_to_pl)
        saas_token = ''
      }
    }
  }

  return {saas_app_id, saas_product_line, saas_token}
}

const transform: AxiosTransform = {
  requestInterceptors: (config) => {
    const saas = getSaasParams()

    config.headers!['Login-Credential'] = window.LoginCredential || getToken()
    config.headers!['Saas-App-Id'] = saas.saas_app_id
    config.headers!['Saas-Product-Line'] = saas.saas_product_line
    config.headers!['Saas-Platform'] = "pc"
    if (!!saas.saas_token) {
      config.headers!['Saas-Token'] = saas.saas_token
    }

    return config
  },
  responseInterceptors(res) {
    if (res && res.headers && res.headers.get('set-saas-token')) {
      const token = res.headers.get('set-saas-token') || ''
      const saasStore = useSaasStore()
      saasStore.updateSaasToken(token)
    }
    return res
  },
  responseInterceptorsCatch(error) {
    if (error.response!.status === 401) {
      ElMessage.error('请重新登录')
      router.replace({path: '/login'})
    }
    return Promise.reject(error)
  }
}

export const axiosRequest = new AxiosPro({
  requestOptions: {
    apiUrl: import.meta.env.VITE_BASE_API
  },
  transform,
  withCredentials: true
})

export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(transform.requestInterceptors)
axiosInstance.interceptors.response.use(transform.responseInterceptors)
