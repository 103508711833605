<script lang="ts" setup>
import {useTheme} from '@/hooks/useTheme'
// 将 Element Plus 的语言设置为中文
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const {initTheme} = useTheme()

/** 初始化主题 */
initTheme()
</script>

<template>
  <el-config-provider :locale="zhCn" :message="{ max: 1 }">
    <router-view/>
  </el-config-provider>
</template>


<style lang="scss">
/* 内联表单默认宽度 */
.el-select {
  --el-select-width: 200px !important;
}
</style>

